<div class="container">
  <div *ngIf="dataSource.data.length > 0; then withData; else noData"></div>
</div>

<ng-template #noData> No se encontraron pedidos pendientes </ng-template>

<ng-template #withData class="mat-elevation-z0">
  <div class="container-table">
    <table mat-table [dataSource]="dataSource" matSort class="table-first">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <!--<mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" color="primary">
        </mat-checkbox>-->
        </th>
        <td  class="select" mat-cell *matCellDef="let row" style="text-transform: capitalize">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; setElements($event.checked, row)" [checked]="row.checked">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="dateCreation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td class="dateCreation text-md" mat-cell *matCellDef="let row" style="text-transform: capitalize"> {{ convertTimestampToDate(row.dateCreation) | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Clientes</th>
        <td class="client text-md" mat-cell *matCellDef="let row" style="text-transform: capitalize">
          {{ row.client }}
        </td>
      </ng-container>

      <!-- Numero de comprobante Column -->
      <ng-container matColumnDef="numberReceipt">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Nº
          Comprobante
        </th>
        <td class="numberReceipt text-md" mat-cell *matCellDef="let row"> {{row.numberReceipt}} </td>
      </ng-container>

      <!-- Ubicacion Column -->
      <ng-container matColumnDef="location">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Ubicacion </th>
        <td class="location text-md" mat-cell *matCellDef="let row"> {{row.location}} </td>
      </ng-container>

      <!-- Servicio Column -->
      <ng-container matColumnDef="service">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
        <td class="service text-md" mat-cell *matCellDef="let row" style="text-transform: capitalize"> {{row.service}} </td>
      </ng-container>

      <!-- Monto Column -->
      <ng-container matColumnDef="amountTotal">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
        <td class="amount text-md" mat-cell *matCellDef="let row"> $ {{row.amountTotal}} </td>
      </ng-container>

      <!-- Estado Column -->
      <ng-container matColumnDef="status">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td class="status" mat-cell *matCellDef="let row">
          <div class="user-status pending-label">
            <mat-icon class="user-status-icon icon-sm" fontSet="material-icons-outlined">pending</mat-icon>
            <div class="text-md"> {{row.status}} </div>
          </div>
        </td>
      </ng-container>

      <!-- Estado Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td class="action" mat-cell *matCellDef="let row">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="icnBtn-sm" #menuTrigger (click)="$event.stopPropagation()" disabled>
            <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="ungroupOrders(row)">
              item para el menu
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="row-h-2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-h-2" (click)="selection.toggle(row)"></tr>
    </table>
  </div>

  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
</ng-template>


<!--  <div *ngIf="currentTabDelivery !== 2"> -->
<div>
  <div class="actionButton">
    <!-- Botones -->
    <!--<button mat-stroked-button color='accent'>
      CANCELAR
    </button>-->
    <button mat-flat-button color="primary" (click)="processOrders()">
      <span>PROCESAR</span>
    </button>
  </div>
</div>