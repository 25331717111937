import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private afAuth: AngularFireAuth,
    private fns: AngularFireFunctions
  ) {}


  async login(email: string, password: string): Promise<any> {
    const result = await this.afAuth.signInWithEmailAndPassword(email, password);
    return result;
  }

  async logOut(): Promise<any> {
    // this.userDataServ.stopListenUserData();

    this.afAuth.signOut().then(() => {
      window.location.href = window.location.href;
    });
  }

  recoberyPassword(email): Observable<any> {
    const callable = this.fns.httpsCallable('cmUsers-recoveryPass');
    return callable({email}); 
  }
}
