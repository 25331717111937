<div class="container">
  <div *ngIf="dataSource.data.length > 0; then withData; else noData"></div>
</div>

<ng-template #noData> No se encontraron pedidos pendientes </ng-template>

<ng-template #withData class="mat-elevation-z0">
  <div class="container-table">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z0 table-first">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td class="select" mat-cell *matCellDef="let row" style="text-transform: capitalize">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; setElements($event.checked, row)" [checked]="row.checked">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="dateCreation">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td class="dateCreation text-md" mat-cell *matCellDef="let row" style="text-transform: capitalize"> {{
          convertTimestampToDate(row.dateCreation) | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="client">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header>Clientes</th>
        <td class="client text-md" mat-cell *matCellDef="let row" style="text-transform: capitalize">
          {{ row.client }}
        </td>
      </ng-container>

      <!-- Monto Column -->
      <ng-container matColumnDef="amount">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Monto Final</th>
        <td class="amount text-md" mat-cell *matCellDef="let row"> $ {{row.deliveryOrders  | ordersAmountQuantity}} </td>
      </ng-container>

      <!-- Estado Column -->
      <ng-container matColumnDef="status">
        <th class="text-sm" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td class="status text-md" mat-cell *matCellDef="let row">
          <div class="user-status pending-label">
            <mat-icon class="user-status-icon icon-sm" fontSet="material-icons-outlined">pending</mat-icon>
            <div class="text-md"> {{row.status}} </div>
          </div>
        </td>
      </ng-container>

      <!-- Estado Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td class="action" mat-cell *matCellDef="let row">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="icnBtn-sm" #menuTrigger (click)="$event.stopPropagation()">
            <mat-icon class="material-symbols-outlined icon-sm">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="ungroupOrders(row)" class="text-md">
              Desagrupar
            </button>
            <button mat-menu-item disabled class="text-md">
              Descargar pdf
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td class="expand" mat-cell *matCellDef="let element">
          <button class="icnBtn-sm" mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon *ngIf="expandedElement !== element" class="icon-sm">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element" class="icon-sm">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

            <div class="table-detalle-container">
              <table class="tabla-detalle">
                <tbody>
                  <!-- titulos de las columnas -->
                  <tr class="header-expan text-sm">
                    <td>Nº Comprobante</td>
                    <td>Ubicacion</td>
                    <td>Servicio</td>
                    <td>Monto</td>
                  </tr>

                  <tr class="data-expan text-md" *ngFor="let order of element.deliveryOrders">

                    <td class="number">
                      {{order.numberReceipt}}
                    </td>

                    <td class="location">
                      {{order.location}}
                    </td>

                    <td class="service" style="text-transform: capitalize">
                      {{order.service}}
                    </td>

                    <td class="amount">
                      $ {{order.amountTotal}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand" class="row-h-2"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" (click)="selection.toggle(row)"
        class="example-element-row row-h-2" [class.example-expanded-row]="expandedElement === element"
        [routerLink]="['/consumos', 'detalle', element.id]">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>

  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
</ng-template>

<div>
  <div class="actionButton">
    <!-- Botones -->
    <!--<button mat-stroked-button color='accent'>
      CANCELAR
    </button>-->
    <button mat-flat-button color="primary" (click)="changeStatus('Enviado a Cliente')">
      <span>APROBADO</span>
    </button>
  </div>

  <div class="buttonVisualizar">
    <button class="visualizar" mat-stroked-button>
      VISUALIZAR
    </button>
  </div>
</div>