import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordersAmountQuantity'
})
export class OrdersAmountQuantityPipe implements PipeTransform {

  transform(arrayOrdersClient): number {
    let mountFinal = 0;

    arrayOrdersClient.forEach(order => {
      mountFinal += order.amountTotal;
    });

    return mountFinal;
  }

}
