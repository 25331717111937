export const environment = {
  production: false,
  firebase: {
    emulator: false,
    apiKey: 'AIzaSyCFMgC-WUXqBZ-Fp2am87pHLczbY5yKhrs',
    authDomain: 'butaco-dev.firebaseapp.com',
    projectId: 'butaco-dev',
    storageBucket: 'butaco-dev.appspot.com',
    messagingSenderId: '688851361388',
    appId: '1:688851361388:web:7279277e8643d2664b8249',
    measurementId: 'G-4ZQ564RW1R'
  }
};
