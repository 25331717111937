import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import localeEsArExt from '@angular/common/locales/extra/es-AR';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButacoInterceptor } from './shared/services/interceptor';
import { environment } from 'src/environments/environment';
import { FileSaverModule } from 'ngx-filesaver';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { ConsumptionRoutingModule } from './pages/consumption/consumption-routing.module';

registerLocaleData(localeEsAr, 'es-AR', localeEsArExt);

export function tokenGetter(): any {
  return localStorage.getItem('butaco-token');
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FileSaverModule,
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ConsumptionRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: ButacoInterceptor, multi: true
    },
    //{ provide: USE_FUNCTIONS_EMULATOR, useValue: environment.firebase.emulator ? ['localhost', 5001] : undefined },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
