<div class="container-detail-delivery-order">
  <div class="list">

    <div class="list-item">
      <h3 class="header">Cliente</h3>
      <mat-divider></mat-divider>
      <p *ngIf="!loading" class="client">
        {{consumDetail.client}}
      </p>
    </div>

    <!--<div class="reason">
      <h3 class="header">Razón social a facturar</h3>
      <mat-divider></mat-divider>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Selecciona una opción</mat-label>
        <mat-select>
          <mat-option *ngFor="let reason of reasonsSocial" [value]="reason.businessName">
            {{reason.businessName}} - {{reason.cuit}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="down">
        <div>
          <span>Ver pdf</span>
          <mat-icon color="primary" fontSet="material-icons-outlined"> description </mat-icon>
        </div>

        <div>
          <span>Total Consumido</span>
          <span> $000 </span>
        </div>
      </div>
    </div>-->

    <div class="list-item">
      <h3 class="header">Servicios</h3>
      <mat-divider></mat-divider>

      <div class="header" *ngIf="services.length  <= 0">
        No existen más servicios
      </div>

      <div *ngFor="let service of services">
        <h5 class="subtitle-meal">Servicio de {{service.service}} - {{service.service}}</h5>
        <div class="down">
          <div>
            <span class="subtitle">Cantidad</span>
            <span class="quantity"> {{service.quantityViandas}} </span>
          </div>

          <div class="precioUnit">
            <span class="subtitle">Precio Unitario</span>
            <span>
              <mat-form-field class="custom-form-field">
                <input matInput [(ngModel)]="service.amount" (ngModelChange)="updateAmount(service.amount, service.service)">
              </mat-form-field>
            </span>
          </div>

          <div>
            <span class="subtitle">Precio Total</span>
            <span class="quantity">{{service.quantityViandas * service.amount}} </span>
          </div>
        </div>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loading"></mat-progress-bar>

    <div class="total">
      <span class="text-total">
        Total
      </span>
      <span class="quantity-total">
        ${{quantityTotal}}
      </span>
    </div>
  </div>

  <div class="details">
    <div>
      <h3 class="header">Detalle</h3>
    </div>
    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loading"></mat-progress-bar>

    <div class="header" *ngIf="details.length  <= 0">
      No existen más detalle de consumo
    </div>

    <div class="content-table"> 
      <div *ngFor="let consum of details">
        <div>
          <h3 class="header" *ngIf="consum.deliveryOrders.length > 0">
            <mat-icon color="primary" fontSet="material-icons-outlined"> location_on </mat-icon>{{consum.location}} 
              <button mat-icon-button (click)="ungroupOrders(consum)">
                <mat-icon fontSet="material-icons-outlined" color="accent">delete</mat-icon>
              </button>
            </h3>
        </div>

        <table mat-table [dataSource]="consum.deliveryOrders" class="mat-elevation-z0" *ngIf="consum.deliveryOrders.length > 0">

          <!-- date column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td class="date" mat-cell *matCellDef="let element"> {{
              convertTimestampToDate(element.dateCreation) | date:'dd/MM/yyyy' }} </td>
          </ng-container>

          <!-- num column -->
          <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef> N° Orden de entrega </th>
            <td class="num" mat-cell *matCellDef="let element"> {{element.numberReceipt}} </td>
          </ng-container>

          <!-- concept column -->
          <ng-container matColumnDef="concept">
            <th mat-header-cell *matHeaderCellDef> Concepto </th>
            <td class="concept" mat-cell *matCellDef="let element"> <div class="capitalize-first-letter">{{element.service}} </div></td>
          </ng-container>

          <!-- quantity column -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
            <td class="quantity" mat-cell *matCellDef="let element" class="quantity">
              {{element.viandas_quantity}}
            </td>
          </ng-container>

          <!-- amount column -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Precio </th>
            <td mat-cell *matCellDef="let element" class="precio input">
                <input [(ngModel)]="element.amount">
            </td>
          </ng-container>

           <!-- button remove column -->
           <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td class="remove" mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="removeOrder(element)">
                <mat-icon fontSet="material-icons-outlined" color="accent">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="actionButton">
    <!-- Botones -->
    <button mat-stroked-button color='accent' (click)="cancelChanges()">
      CANCELAR
    </button>
    <button mat-flat-button color="primary" (click)="saveChanges()">
      <span>GUARDAR</span>
    </button>
  </div>
</div>