import { Component, Input, ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConsumptionService } from 'src/app/shared/services/consumption.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'butaco-table-consumption-details',
  templateUrl: './table-consumption-details.component.html',
  styleUrls: ['./table-consumption-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableConsumptionDetailsComponent {
  
  displayedColumns: string[] = ['dateCreation', 'client', 'amount', 'status','action'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any | null;
  dataSource: any = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  consumptions: any[];
  tabs = 0;

  @Input() set data(data: any) {
    if (data) {
      this.setDataTable(data);
    }
  }

  @Input() set tab(tab: any) {
   this.tabs = tab;
  }

  elementsCheck: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private consumptionServ: ConsumptionService) { }

  setDataTable(data) {
    
    this.dataSource.data = data;
    this.consumptions = data;

    this.dataSource.data.forEach(dato => {
      dato.checked = false;
      dato.amountTotal = 0;
    });
    
    this.obtenerDatos()
      .then((datos) => {
        this.dataSource.data = datos; // Asigna los datos a la propiedad this.dataSource.data
        this.dataSource.paginator = this.paginator; // Asigna this.paginator cuando los datos están disponibles
        this.dataSource.sort = this.sort;
      })
      .catch((error) => {
        console.error(error); // Maneja el caso de error
      });
  }

  obtenerDatos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const datos = this.consumptions;
      if (datos) {
        resolve(datos); // Resuelve la promesa con los datos
      } else {
        reject('No se pudieron obtener los datos'); // Rechaza la promesa con un mensaje de error
      }
    });
  }

  setElements($event, element) {
    if ($event != undefined) {
      if ($event) {
        this.elementsCheck.push(element);
        element.checked = true;
      } else {
        this.elementsCheck = this.elementsCheck.filter(elem => elem.id != element.id);
        element.checked = false;
      }
    }

    this.dataSource.data = this.dataSource.data.map(orden => ({
      ...orden,
      checked: orden.client === element.client ? orden.checked : false,
    }));
  }

  changeStatus(status) {
    this.elementsCheck = this.dataSource.data.filter(element => element.checked == true);
    this.consumptionServ.changeMultipleUsagesStatus(this.elementsCheck, status);
    this.elementsCheck = [];
  }

  ungroupOrders(order){
    this.consumptionServ.changeStatusConsumptionDetail(order.id, 'Para Procesar').then((res) => {
      this.consumptionServ.changeMultipleUsagesStatus(order.deliveryOrders, 'Para Procesar');
    });
  }

  convertTimestampToDate(timestamp) {
    // Verifica si timestamp es realmente un objeto Timestamp
    if (timestamp instanceof Timestamp) {
      // Convierte el objeto Timestamp en una fecha de JavaScript
      return timestamp.toDate();
    } else {
      // Si no es un objeto Timestamp, devuelve null o una cadena vacía, según tu preferencia.
      return null; // o return '';
    }
  }

  

}
