import { Component, Input, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConsumptionService } from 'src/app/shared/services/consumption.service';
import { ConsumptionDetail } from 'src/app/models/consumption.model';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'butaco-table-delivery-orders',
  templateUrl: './table-delivery-orders.component.html',
  styleUrls: ['./table-delivery-orders.component.scss']
})
export class TableDeliveryOrdersComponent {

  displayedColumns: string[] = ['select', 'dateCreation', 'client', 'numberReceipt', 'location', 'service', 'amountTotal', 'status', 'action'];
  dataSource: any = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  consumptions: any[];
  tabs = 0;

  @Input() set data(data: any) {
    if (data) {
      this.setDataTable(data);
    }
  }

  @Input() set tab(tab: any) {
    this.tabs = tab;
  }

  elementsCheck = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private consumptionServ: ConsumptionService) { }

  setDataTable(data) {
    this.dataSource.data = data;
    this.consumptions = data;

    this.dataSource.data.forEach(dato => {
      dato.checked = false;
      dato.amountTotal = parseFloat(dato.amount) * dato.viandas_quantity;
    });

    this.obtenerDatos()
      .then((datos) => {
        this.dataSource.data = datos; // Asigna los datos a la propiedad this.dataSource.data
        this.dataSource.paginator = this.paginator; // Asigna this.paginator cuando los datos están disponibles
        this.dataSource.sort = this.sort;
      })
      .catch((error) => {
        console.error(error); // Maneja el caso de error
      });
  }

  obtenerDatos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const datos = this.consumptions;
      if (datos) {
        resolve(datos); // Resuelve la promesa con los datos
      } else {
        reject('No se pudieron obtener los datos'); // Rechaza la promesa con un mensaje de error
      }
    });
  }

  setElements($event, element) {
    if ($event != undefined) {
      if ($event) {
        this.elementsCheck.push(element);
        element.checked = true;
      } else {
        this.elementsCheck = this.elementsCheck.filter(elem => elem.id != element.id);
        element.checked = false;
      }
    }

    this.dataSource.data = this.dataSource.data.map(orden => ({
      ...orden,
      checked: orden.client === element.client ? orden.checked : false,
    }));
  }

  async processOrders() {
    this.elementsCheck = this.dataSource.data.filter(element => element.checked == true);
    if (this.elementsCheck.length > 0) {  
    
      try {
        const comsupDetails = await this.consumptionServ.searchObjectBy('client', this.elementsCheck[0].client, 'status', 'Para Procesar').toPromise();
  
        const consumptionDetail = {
          incrementalNumber: 0,
          status: 'Pendiente Aprobación',
          dateCreation: new Date(),
          dateEdit: new Date(),
          deliveryOrders: this.elementsCheck,
          client: this.elementsCheck[0].client,
          clientId: this.elementsCheck[0].clientId,
          modifications: this.sortModifications([]),
        };
  
        const commonReceipt = this.elementsCheck.some(elementCheck =>
          comsupDetails.some(comsup =>
            comsup.deliveryOrders.some(comsupDetail =>
              comsupDetail.numberReceipt === elementCheck.numberReceipt
            )
          )
        );
  
        if (commonReceipt) {
          const findOrder = comsupDetails.find(comsup =>
            comsup.deliveryOrders.some(comsupDetail =>
              this.elementsCheck.some(elementCheck =>
                comsupDetail.numberReceipt === elementCheck.numberReceipt
              )
            )
          );
  
          findOrder.modifications = this.sortModifications(findOrder.modifications); 
          findOrder.deliveryOrders = this.elementsCheck; 
          
          findOrder.status = 'Pendiente Aprobación';
         
          this.consumptionServ.changeConsumptionDeatilStatus(findOrder);
        } else {
          // Create consumption detail and change status
          await this.consumptionServ.createConsumptionDetail(consumptionDetail);
        }
  
        // Always change status
        this.consumptionServ.changeMultipleUsagesStatus(this.elementsCheck, 'Pendiente Aprobación');
      } catch (error) {
        console.error('Error al buscar el objeto:', error);
      }
    } else {
      console.log('No hay ordenes seleccionadas');
    }
  }



  sortModifications(modificationsBefore) {
    let modifications = this.elementsCheck; 
    modificationsBefore.push({ deliveryOrder: this.elementsCheck });  
    return modificationsBefore;
  }

  convertTimestampToDate(timestamp) {
    // Verifica si timestamp es realmente un objeto Timestamp
    if (timestamp instanceof Timestamp) {
      // Convierte el objeto Timestamp en una fecha de JavaScript
      return timestamp.toDate();
    } else {
      // Si no es un objeto Timestamp, devuelve null o una cadena vacía, según tu preferencia.
      return null; // o return '';
    }
  }

}
