import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsumptionService } from 'src/app/shared/services/consumption.service';
import { Timestamp } from 'firebase/firestore';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'butaco-detail-delivery-order',
  templateUrl: './detail-delivery-order.component.html',
  styleUrls: ['./detail-delivery-order.component.scss'],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
  ]
})
export class DetailDeliveryOrderComponent implements OnInit {

  displayedColumns: string[] = ['date', 'num', 'concept', 'quantity', 'amount', 'remove'];

  private consumoSubscription: Subscription;
  private storageIdConsum = 'selected_consumption_id';
  consumDetail: any;
  loading = true;
  reasonsSocial: any;
  details: any[] = [];
  services: any[] = [];
  quantityTotal: any;
  consumOrder = [];

  constructor(
    private consumptionServ: ConsumptionService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getDataOrder();
  }

  getDataOrder() {
    // Obtener el consumoId del parámetro en la URL
    let consumptionId = this.route.snapshot.params['id'];

    const selectedConsumptionId = sessionStorage.getItem(this.storageIdConsum);

    if (selectedConsumptionId && (selectedConsumptionId === consumptionId || !(/[0-9]/.test(consumptionId) && /[a-zA-Z]/.test(consumptionId)))) {
      // Si el consumo está en SessionStorage y sigue siendo el mismo is, utilizarlo
      consumptionId = selectedConsumptionId;
    } else {
      // Si no está en SessionStorage, guardarlo
      consumptionId = consumptionId;
      if (/[0-9]/.test(consumptionId) && /[a-zA-Z]/.test(consumptionId)) {
        sessionStorage.setItem(this.storageIdConsum, consumptionId);
      }
    }

    //obtener el detalle de consumo con el id final
    this.consumoSubscription = this.consumptionServ.getConsumptionDetailById(consumptionId).subscribe(
      (consumption) => {
        this.consumDetail = consumption;
        this.consumDetail.id = consumptionId;

        this.getReasonsSocials(consumption.clientId);
        this.sortTableDetail(consumption);
        this.sortServices(consumption);

        this.consumoSubscription.unsubscribe();
        if (this.consumDetail.status != 'Para Procesar') {
          this.router.navigate(['/consumos', 'Detalle de consumo', this.consumDetail.deliveryOrders[0].numberReceipt], { replaceUrl: true });
        }

      },
      (error) => {
        console.error('Error al obtener el consumo:', error);
      }
    );


  }

  sortTableDetail(consumptionsDetail) {
    let details = [];

    details = consumptionsDetail.deliveryOrders.reduce((result, order) => {
      let findOrder = result.findIndex(detail => detail.location == order.location);

      if (findOrder != -1) {

        result[findOrder].deliveryOrders.push(order);
      } else {
        result.push({ location: order.location, deliveryOrders: [order], id: order.id });
      }

      return result;
    }, details);

    this.details = details;
  }

  sortServices(comsumptions) {
    let services = [];

    services = comsumptions.deliveryOrders.reduce((result, order) => {
      let findOrder = result.findIndex(detail => detail.service == order.service);

      if (findOrder != -1) {
        result[findOrder].quantityViandas += order.viandas_quantity;
        if (order.amount > result[findOrder].amount) {
          result[findOrder].amount = order.amount;
        }

      } else {
        result.push({
          service: order.service,
          quantityViandas: order.viandas_quantity,
          amount: order.amount,
        });
      }

      return result;
    }, services);

    this.services = services;

    this.updateQuantity();
  }

  updateQuantity() {
    let quantityTotal = 0;
    this.services.forEach(service => {
      quantityTotal += (parseFloat(service.quantityViandas) * parseFloat(service.amount));
    });

    this.quantityTotal = quantityTotal;
  }

  async getReasonsSocials(id) {
    const reasons = await this.consumptionServ.getSocialReasons(id);
    this.reasonsSocial = reasons;
    this.loading = false;
  }

  updateAmount(amount, service) {
    this.details.forEach(detail => {
      detail.deliveryOrders = detail.deliveryOrders.map(order => ({
        ...order,
        amount: order.service === service ? amount : order.amount
      }));
    });

    this.consumDetail.deliveryOrders.forEach(consum => {
      if (consum.service = service) {
        consum.amount = amount;
      }
    });

    this.updateQuantity();
  }

  convertTimestampToDate(timestamp) {
    // Verifica si timestamp es realmente un objeto Timestamp
    if (timestamp instanceof Timestamp) {
      // Convierte el objeto Timestamp en una fecha de JavaScript
      return timestamp.toDate();
    } else {
      // Si no es un objeto Timestamp, devuelve null o una cadena vacía, según tu preferencia.
      return null; // o return '';
    }
  }

  ungroupOrders(consumDetail) {
    this.consumOrder.push(...this.consumDetail.deliveryOrders.filter(consum => consum.location == consumDetail.location));
    this.consumDetail.deliveryOrders = this.consumDetail.deliveryOrders.filter(consum => consum.location != consumDetail.location);
    this.sortTableDetail(this.consumDetail);
    this.sortServices(this.consumDetail);
  }

  removeOrder(order) {
    this.consumOrder.push(...this.consumDetail.deliveryOrders.filter(orderFinal => orderFinal.id == order.id));
    this.consumDetail.deliveryOrders = this.consumDetail.deliveryOrders.filter(orderFinal => orderFinal.id != order.id);
    this.sortTableDetail(this.consumDetail);
    this.sortServices(this.consumDetail);
  }

  async saveChanges() {

    try {
      await this.consumptionServ.changeMultipleUsagesStatuss(this.consumOrder, 'Para Procesar');

      if (this.consumDetail.deliveryOrders.length > 0) {
        await this.consumptionServ.updateConsumptionDetail(this.consumDetail.id, this.consumDetail.deliveryOrders, 'deliveryOrders');
      } else {
        await this.consumptionServ.updateConsumptionDetail(this.consumDetail.id, 'Para Procesar', 'status');
        this.router.navigate(['/consumos']);
      }

      this.consumDetail.modifications.push({ deliveryOrder: this.consumDetail.deliveryOrders });
      await this.consumptionServ.updateConsumptionDetail(this.consumDetail.id, this.consumDetail.modifications, 'modifications');

      await this.openSnak(
        `El detalle de consumo fue modificado exitosamente`,
        'OK',
        'snackBarSucces'
      )

    } catch (error) {
      console.error('Error en la actualización en lote:', error);
    }
  }

  async openSnak(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: [className],
      verticalPosition: 'top',
      horizontalPosition: 'end',
    });
  }

  cancelChanges() {
    this.router.navigate(['/consumos']);
  }
}

