import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ConsumptionMainComponent } from './components/consumption-main/consumption-main.component';
import { DetailDeliveryOrderComponent } from './components/detail-delivery-order/detail-delivery-order.component';

const routes: Routes = [
  {
    path: '',
    component: ConsumptionMainComponent, 
  },

  {
    path: ':detalle/:id',
    component: DetailDeliveryOrderComponent, 
  },

];

export const routing = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConsumptionRoutingModule { }
