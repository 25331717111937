import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
@Injectable()
export class ButacoInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthenticationService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = new HttpHeaders();
        // console.log(request.url);

        // if (!request.url.includes('/auth/login')) {
        //     const tokenActual = this.auth.getCurrentToken();

        //     const tokenPayload = this.jwtHelper.decodeToken(tokenActual);
        //     headers = headers.set('Authorization', `Bearer ${tokenActual}`);
        // }


        const cloneReq = request.clone({ headers });
        return next.handle(cloneReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {

                }
                return throwError(error);
            })
        );
    }
}
