import { ConsumptionService } from 'src/app/shared/services/consumption.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TableDeliveryOrdersComponent } from '../table-delivery-orders/table-delivery-orders.component';
@Component({
  selector: 'butaco-consumption-main',
  templateUrl: './consumption-main.component.html',
  styleUrls: ['./consumption-main.component.scss']
})
export class ConsumptionMainComponent implements OnInit {

  @ViewChild(TableDeliveryOrdersComponent) tableComponent: TableDeliveryOrdersComponent;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  consumptionsSubscription: Subscription;
  currentTab: number = 0;
  currentTabDelivery: number = 0;

  public data: any[] = [];

  dataPending: any[] = [];
  dataProcessed: any[] = [];
  dataApproved: any[] = [];
  loadingConsumptionList = true;

  constructor(private consumptionServ: ConsumptionService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getDates();
    this.getConsumos();
    this.getConsumptionDetail();
    this.loadingConsumptionList = false;
  }

  getDates() {
    const currentDate = new Date();

    this.range = this.formBuilder.group({
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    });

    //si la fecha de inicio o de fin ca,bia se actualizan los datos de ordenes de entregar y detalle de consumo
    this.range.get('start').valueChanges.subscribe(() => {
      this.getConsumos();
      this.getConsumptionDetail();
    });
    this.range.get('end').valueChanges.subscribe(() => {
      this.getConsumos();
      this.getConsumptionDetail();
    });

  }

  getConsumos() {
    const start = new Date(this.range.get('start').value);
    const end = new Date(this.range.get('end').value);
    this.consumptionsSubscription = this.consumptionServ.getAllConsumptionsRange('',start, end).subscribe((res) => {
      const consumptions = res;
      this.sortArraysDeliveryOrders(this.sortAlphabetically(consumptions));
    })
  }

  getConsumptionDetail() {
    const start = new Date(this.range.get('start').value);
    const end = new Date(this.range.get('end').value);
    this.consumptionsSubscription = this.consumptionServ.getAllConsumptionsDetailRange(start, end).subscribe((res) => {
      const consumptionsDetail = res;
      this.filterDataProcessed(this.sortAlphabetically(consumptionsDetail));
    })
  }

  sortArraysDeliveryOrders(array) {
    this.filterDataPending(array);
    this.filterDataCancelled(array);
  }

  onChangeSelectedTab(event: any) {
    this.currentTab = event.index;
  }

  filterDataPending(dataArray) {
    this.dataPending = dataArray.filter(data => data.status == 'Para Procesar');//pendientes
  }

  filterDataProcessed(dataArray) {
    this.dataProcessed = dataArray.filter(data => data.status == 'Pendiente Aprobación');//procesado
  }

  filterDataCancelled(dataArray) {
    this.dataApproved = dataArray.filter(data => data.status == 'Enviado a Cliente');//aprobados
  }

  sortAlphabetically(dataArray) {
    dataArray = dataArray.map((item) => ({
      ...item,
      location: item.location || '', // Assign an empty string if location is not present
    }));

    const rta = dataArray.sort((a, b) => a.client.localeCompare(b.client) ||
      a.location.localeCompare(b.location) ||
      a.dateCreation.seconds - b.dateCreation.seconds);
    return rta;
  }
}
