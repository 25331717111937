<div class="container-consumption">
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onChangeSelectedTab($event)">
        <!--ORDENES DE ENTREGA-->
        <mat-tab>
            <ng-template mat-tab-label>
                <span [ngStyle]="{ color: currentTab === 0 ? '#64886A' : 'gray' }"> Ordenes de entrega</span>
            </ng-template>
        </mat-tab>
        <!--DETALLE DE CONSUMO-->
        <mat-tab>
            <ng-template mat-tab-label>
                <span [ngStyle]="{ color: currentTab === 1 ? '#64886A' : 'gray' }"> Detalle de Consumo </span>
            </ng-template>
        </mat-tab>
        <!--FACTURADO-->
        <mat-tab disabled>
            <ng-template mat-tab-label>
                <span [ngStyle]="{ color: currentTab === 2 ? '#64886A' : 'gray' }">Facturado</span>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <mat-progress-bar color="primary" mode="indeterminate" *ngIf="loadingConsumptionList">
    </mat-progress-bar>

    <!--Panel de fecha-->
    <div class="date-and-search">
        <form>
            <mat-form-field appearance="fill">
                <mat-label>Fecha de entrega</mat-label>
                <mat-date-range-input color="primary" [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start">
                    <input matEndDate formControlName="end">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker" color="primary"></mat-datepicker-toggle>
                <mat-date-range-picker #picker color="primary"></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </form>
    </div>

    <!--Contenido de Ordenes de entrega -->
    <div class="container-tab" *ngIf="currentTab == 0">
        <butaco-table-delivery-orders [tab]="currentTab" [data]="dataPending">
        </butaco-table-delivery-orders>
    </div>

    <!--Contenido de deatalle de consumo -->
    <div class="container-tab" *ngIf="currentTab == 1">
        <butaco-table-consumption-details [tab]="currentTab" [data]="dataProcessed">
        </butaco-table-consumption-details>
    </div>

    <!--Contenido de Facturado -->
    <div class="container-tab" *ngIf="currentTab == 2">
        <butaco-table-delivery-orders [tab]="currentTab" [data]="dataApproved">
        </butaco-table-delivery-orders>
    </div>